import React from 'react';

function TermsOfService() {
  return (
    <div className="container mx-auto p-8 text-gray-800">
      <h1 className="text-4xl font-bold mb-6">Terms and Conditions</h1>

      <h2 className="text-2xl font-semibold mt-6 mb-4">1. Introduction</h2>
      <p className="text-lg mb-4">
        Welcome to The Mental Health Program Ltd (“we,” “our,” or “us”). By accessing or using our services, including monthly e-learning courses, in-person assemblies, and premium Substack subscriptions, you agree to these Terms and Conditions (“Terms”). Please read these Terms carefully. If you do not agree, you must not use our services.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">2. Scope of Services</h2>
      <p className="text-lg mb-4">
        We provide psychoeducation and mental health literacy resources based on peer-reviewed research. Our services are intended solely for educational purposes and do not constitute professional medical or psychological advice, diagnosis, or treatment.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">3. No Medical Advice</h2>
      <ul className="list-disc list-inside mb-4">
        <li>The information provided by The Mental Health Program Ltd is not a substitute for professional medical or psychological advice.</li>
        <li>Always seek advice from a licensed healthcare provider for any specific mental health concerns or emergencies.</li>
        <li>If you are experiencing a crisis or medical emergency, contact your local emergency services immediately.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">4. Limitations of Liability</h2>
      <ul className="list-disc list-inside mb-4">
        <li><strong>No Guarantees:</strong> We make no warranties regarding the accuracy, completeness, or reliability of the information provided.</li>
        <li><strong>No Liability:</strong> We are not liable for any direct, indirect, incidental, or consequential damages arising from the use or inability to use our services, including reliance on any information provided.</li>
        <li><strong>Educational Purpose Only:</strong> The Mental Health Program Ltd disclaims all responsibility for any actions taken or decisions made based on the content provided.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">5. User Responsibilities</h2>
      <ul className="list-disc list-inside mb-4">
        <li>Users agree to use our materials responsibly, acknowledging that our services are supplemental resources and not comprehensive mental health solutions.</li>
        <li>Schools and institutions using our services are solely responsible for ensuring compliance with their own policies and applicable laws.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">6. Intellectual Property Rights</h2>
      <p className="text-lg mb-4">
        All content, including but not limited to videos, articles, and assemblies, is the intellectual property of The Mental Health Program Ltd. No content may be reproduced, distributed, modified, or repurposed without prior written consent from The Mental Health Program Ltd.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">7. Payment and Subscription</h2>
      <p className="text-lg mb-4">
        Access to some of our services may require payment or subscription. Payment terms, including billing cycles and cancellations, will be outlined in a separate agreement or service description at the point of purchase.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">8. Privacy and Data Protection</h2>
      <p className="text-lg mb-4">
        Your use of our services is subject to our Privacy Policy, which outlines how we collect, use, and protect your personal information.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">9. Third-Party Links and Content</h2>
      <p className="text-lg mb-4">
        Our services may include links to third-party websites or content. We are not responsible for the accuracy, reliability, or availability of such external resources and disclaim all liability related to third-party content.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">10. Indemnification</h2>
      <p className="text-lg mb-4">
        By using our services, you agree to indemnify and hold harmless The Mental Health Program Ltd, its directors, employees, and affiliates, from and against any claims, damages, losses, or expenses arising out of your misuse of our services or breach of these Terms.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">11. Governing Law and Dispute Resolution</h2>
      <p className="text-lg mb-4">
        These Terms are governed by and construed in accordance with the laws of [Insert Jurisdiction]. Any disputes arising from these Terms shall be subject to the exclusive jurisdiction of the courts of [Insert Jurisdiction].
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">12. Changes to These Terms</h2>
      <p className="text-lg mb-4">
        We reserve the right to modify or update these Terms at any time. Any changes will be effective upon posting to our website or notifying you directly. Continued use of our services indicates your acceptance of the revised Terms.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">13. Contact Us</h2>
      <p className="text-lg mb-4">
        For any questions or concerns, please contact us at:
      </p>
      <p className="text-lg">
        Email: <a href="mailto:contact@mentalhealthprogram.co.uk" className="text-blue-500 underline">contact@mentalhealthprogram.co.uk</a>
      </p>
    </div>
  );
}

export default TermsOfService;
