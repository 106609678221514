import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../Firebase"; // Ensure correct relative path to firebase.js
import { collection, addDoc } from "firebase/firestore";

function VideoPage() {
  const [showModal, setShowModal] = useState(true); // Controls modal visibility
  const [email, setEmail] = useState(""); // Holds the email input
  const navigate = useNavigate();

  const handleEmailSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log("Attempting to save email to Firestore..."); // Debug log
      await addDoc(collection(db, "emails"), { email });
      console.log("Email stored successfully in Firebase!"); // Success log
      setShowModal(false); // Close the modal after submission
    } catch (error) {
      console.error("Error storing email in Firebase:", error); // Error log
    }
  };

  return (
    <div className="bg-white p-6 sm:p-8 lg:p-12 min-h-screen relative">
    {/* Email Capture Modal */}
    {showModal && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-4 sm:p-6 lg:p-8 rounded-lg shadow-lg text-center max-w-sm sm:max-w-md w-full">
          <h2 className="text-lg sm:text-xl font-semibold mb-4">
            Enter your email to try before you buy
          </h2>
          <p className="text-sm sm:text-base text-gray-600 mb-6">
            Access a free preview of our psychoeducation videos.
          </p>
          <form onSubmit={handleEmailSubmit}>
            <input
              type="email"
              placeholder="Your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-[#4b8f4b]"
            />
            <button
              type="submit"
              className="w-full py-2 sm:py-3 bg-[#4b8f4b] text-white font-semibold rounded hover:bg-[#3a6e3a] transition-colors"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    )}
  
    {/* Main Video Page Content */}
    <h2 className="text-2xl sm:text-3xl lg:text-5xl font-bold text-center text-gray-800 mt-8 sm:mt-12 mb-4">
      Try Before You Buy
    </h2>
    <p className="text-sm sm:text-lg text-center text-gray-700 mb-8 sm:mb-10">
      Here are some clips from one of our assemblies.
    </p>
  
    {/* Video Content Section */}
    <div className="flex flex-col space-y-8 sm:space-y-12 max-w-md sm:max-w-2xl lg:max-w-4xl mx-auto">
   {/* Video 1 */}
<div className="w-full text-left">
  <iframe
    src="https://player.vimeo.com/video/1031462287"
    width="100%"
    height="350"
    frameBorder="0"
    allow="autoplay; fullscreen; picture-in-picture"
    allowFullScreen
    className="rounded-lg shadow-lg mb-4"
  ></iframe>
  <h3 className="text-lg sm:text-xl lg:text-2xl font-semibold text-gray-800">
    The Anterior Cingulate Cortex
  </h3>
  <p className="text-sm sm:text-base lg:text-lg text-gray-700 mt-2">
    The anterior cingulate cortex (ACC) is a critical part of the brain that strengthens through engaging in challenging tasks...
  </p>
</div>

{/* Video 2 */}
<div className="w-full text-left">
  <iframe
    src="https://player.vimeo.com/video/1031453736"
    width="100%"
    height="350"
    frameBorder="0"
    allow="autoplay; fullscreen; picture-in-picture"
    allowFullScreen
    className="rounded-lg shadow-lg mb-4"
  ></iframe>
  <h3 className="text-lg sm:text-xl lg:text-2xl font-semibold text-gray-800">
    The Prefrontal Cortex
  </h3>
  <p className="text-sm sm:text-base lg:text-lg text-gray-700 mt-2">
    The prefrontal cortex (PFC), responsible for executive functions like decision-making and impulse control...
  </p>
</div>
</div>

    {/* Navigation Buttons */}
    <div className="flex flex-col sm:flex-row justify-center items-center mt-8 sm:mt-12 space-y-4 sm:space-y-0 sm:space-x-4">
      <Link to="/join">
        <button className="px-6 sm:px-8 lg:px-12 py-2 sm:py-3 lg:py-4 bg-[#4b8f4b] text-white text-sm sm:text-lg lg:text-xl font-semibold rounded-full hover:bg-[#5fa55f] transition-colors">
          Join
        </button>
      </Link>
      <button
        onClick={() => navigate("/services")}
        className="px-6 sm:px-8 lg:px-12 py-2 sm:py-3 lg:py-4 bg-white text-black text-sm sm:text-lg lg:text-xl font-semibold border border-black rounded-full hover:bg-gray-100 transition-colors"
      >
        Back to Services
      </button>
    </div>
  </div>  
  );
}

export default VideoPage;
