import React, { useEffect } from "react";
import { FaBullseye, FaUsers, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import './WhoWeAre.css';

function WhoWeAre() {
  useEffect(() => {
    // Add animation classes after a short delay for a smooth effect
    const profileBoxes = document.querySelectorAll(".profile-box");
    profileBoxes.forEach((box, index) => {
      setTimeout(() => {
        box.classList.add("fade-in"); // Trigger the fade-in animation
      }, index * 200); // Staggered effect
    });
  }, []);

  return (
    <div className="min-h-screen bg-[#f5f2ec] pt-12 sm:pt-24 px-4 sm:px-8">
    {/* Mission Section */}
    <div className="max-w-xl sm:max-w-3xl mx-auto text-center mb-12 sm:mb-16">
  <div className="flex justify-center mb-4" style={{ marginTop: '20px' }}> {/* Increased margin-top */}
    <FaBullseye className="text-[#4b8f4b] text-3xl sm:text-4xl" /> {/* Icon above title */}
  </div>
  <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-800 mb-4">Our Mission</h2>
  <p className="text-sm sm:text-base lg:text-lg text-gray-700">
    Our mission is to attenuate the demand for mental health provision in schools by providing psychoeducation that is informed by the most cutting-edge and elite research. We aim to dispel the abundance of misinformation on the internet by linking schools directly to accurate, peer-reviewed mental health science.
  </p>
</div>
  
    {/* Team Title */}
    <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold text-gray-800 text-center mb-6 sm:mb-8">The Team</h2>
  
    {/* Profile Section */}
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 max-w-5xl mx-auto mb-12 sm:mb-16">
      {[...Array(5)].map((_, index) => (
        <div
          key={index}
          className="bg-white rounded-3xl shadow-lg p-4 sm:p-6 lg:p-8 text-center relative"
        >
          {/* LinkedIn Icon in Top-Left Corner */}
          {index === 0 || index === 3 || index === 4 ? (
            <a
              href={
                index === 0
                  ? "https://www.linkedin.com/in/eddie-bullock-117294228/"
                  : index === 3
                  ? "https://www.linkedin.com/in/milla-mcnally-a39a5a14a/"
                  : "https://www.linkedin.com/in/branni-harvey-johnston-80479520a/"
              }
              target="_blank"
              rel="noopener noreferrer"
              className="absolute top-4 left-4 text-[#0077b5] hover:text-[#005582] transition-colors"
              title="LinkedIn Profile"
            >
              <FaLinkedin className="text-2xl sm:text-3xl" />
            </a>
          ) : (
            <button
              onClick={() => alert("LinkedIn profile not available")}
              className="absolute top-4 left-4 text-gray-500 hover:text-gray-700 transition-colors"
              title="No LinkedIn Profile"
            >
              <FaLinkedin className="text-2xl sm:text-3xl" />
            </button>
          )}
  
          {/* Profile Picture */}
          <div className="w-20 sm:w-24 lg:w-32 h-20 sm:h-24 lg:h-32 mx-auto mb-4 rounded-full bg-gray-300 overflow-hidden">
            <img
              src={
                index === 0
                  ? "https://github.com/eddiebullock/landing-page-assets/blob/main/IMG_1079.JPG?raw=true"
                  : index === 1
                  ? "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-07%20at%2008.32.46.png?raw=true"
                  : index === 2
                  ? "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-07%20at%2008.32.26.png?raw=true"
                  : index === 3
                  ? "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-07%20at%2008.31.58.png?raw=true"
                  : "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-07%20at%2009.08.35.png?raw=true"
              }
              alt={`Profile ${index + 1}`}
              className="w-full h-full object-cover"
            />
          </div>
  
          {/* Profile Titles */}
          <h3 className="text-lg sm:text-xl font-semibold text-gray-800">
            {index === 0
              ? "Eddie"
              : index === 1
              ? "Yumnah"
              : index === 2
              ? "Matea"
              : index === 3
              ? "Milla"
              : "Branni"}
          </h3>
  
          {/* Profile Subtext */}
          <p className="text-sm sm:text-base text-gray-600 mt-2 mb-4">
            {index === 0
              ? "After delivering in person mental health assemblies in schools, Eddie saw firsthand the lack of evidence-based knowledge and the spread of misinformation among young people. This inspired him to found The Mental Health Program, aimed at bridging this gap by providing schools with direct access to rigorous, research-backed mental health insights."
              : index === 1
              ? "Yumnah is a doctoral researcher under the supervision of Professor Sir Simon Baron-Cohen, leading a project that investigates sex differences in neonates. She has contributed to multiple peer-reviewed journals and collaborated on international scientific investigations, including the AIMS-2-TRIALS project."
              : index === 2
              ? "Matea is a doctoral researcher exploring talent in autism. Her work spans cognitive and psychophysical methods, with a particular focus on visual sensory processing and sensory processing traits in autism."
              : index === 3
              ? "Milla is a mental health and wellbeing officer at ASK, a Geneva-based nonprofit that supports young people with learning differences in their educational journeys."
              : "Branni works at Weber Shandwick, one of the world’s largest marketing and communications firms. He has been instrumental in expanding the reach of The Mental Health Program."}
          </p>
        </div>
      ))}
    </div>
  
    {/* Careers Section */}
    <div className="flex flex-col md:flex-row items-center max-w-5xl mx-auto py-12 sm:py-16 px-4 sm:px-8 lg:px-16 bg-[#f5f2ec]">
      <div className="md:w-1/2 md:mr-8 mb-8 md:mb-0">
        <div className="flex items-center mb-4">
          <FaUsers className="text-[#4b8f4b] text-2xl sm:text-3xl mr-2" />
          <p className="text-[#4b8f4b] text-sm font-bold">CAREERS</p>
        </div>
        <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-black mb-4">Join Our Team</h2>
        <p className="text-sm sm:text-base lg:text-2xl text-gray-700 mb-6">
          We’re always on the lookout for the best and brightest. Think you might be an awesome candidate?
        </p>
        <Link
          to="/contact"
          className="inline-block py-2 sm:py-3 px-6 sm:px-8 bg-white border-2 border-black text-black font-semibold rounded-full hover:bg-gray-100 hover:scale-105 transform transition-transform duration-200"
        >
          Contact
        </Link>
      </div>
      <div className="md:w-1/2 flex justify-center">
        <div className="bg-[#4b8f4b] p-4 sm:p-6 rounded-3xl shadow-lg">
          <img
            src="https://github.com/eddiebullock/landing-page-assets/blob/main/annie-spratt-hCb3lIB8L8E-unsplash.jpg?raw=true"
            alt="Join Our Team"
            className="w-full h-auto max-w-xs sm:max-w-sm rounded-3xl"
          />
        </div>
      </div>
    </div>
  </div>
    );
}

export default WhoWeAre;
