import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { Pagination, EffectCoverflow, Mousewheel } from 'swiper/modules';
import { FaStar, FaPlay } from 'react-icons/fa';
import { Link } from "react-router-dom"; 
import './Services.css';

function Services() {
  useEffect(() => {
    // Immediate fade-in for elements with `.fade-in-load`
    const loadFadeElements = document.querySelectorAll('.fade-in-load');
    loadFadeElements.forEach((el) => el.classList.add('visible'));

    // Scroll-triggered fade-in for elements with `.fade-in`
    const fadeElements = document.querySelectorAll('.fade-in');

    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('visible');
              observer.unobserve(entry.target); // Stop observing once visible
            }
          });
        },
        { threshold: 0.2 }
      );

      fadeElements.forEach((el) => observer.observe(el));
      return () => observer.disconnect();
    } else {
      // Fallback for older browsers
      fadeElements.forEach((el) => el.classList.add('visible'));
    }
  }, []);

  return (
    <div className="bg-[#f5f2ec] min-h-screen"> {/* Cream background for entire page */}
      <section className="services">
{/* Green Section */}
<div className="bg-[#4b8f4b] pt-12 pb-4 text-center px-6 sm:px-12 lg:px-20 fade-in-load">
  <div className="flex justify-center mb-0 -mt-0 fade-in-load">
    <img
      src="https://raw.githubusercontent.com/eddiebullock/landing-page-assets/f0401b898b7a40c5c8e96ae78ab15d7422333d9a/4IrlTa01.svg"
      alt="Logo"
      className="w-24 h-24 sm:w-32 sm:h-32 lg:w-40 lg:h-40 mt-8 -mb-4"
    />
  </div>
  <h1 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-black leading-tight mb-6 fade-in-load">
    Seamlessly Integrate Mental <br /> Health Education into Your School
  </h1>
  <p className="text-sm sm:text-base lg:text-lg mt-4 max-w-md sm:max-w-lg lg:max-w-2xl mx-auto text-black fade-in-load">
    Leverage the Mental Health Program’s access to cutting-edge mental health science and provide your students with actionable strategies to improve their mental health.
  </p>
  <Link to="/join">
    <button className="mt-6 px-6 py-2 sm:px-8 sm:py-3 lg:px-10 lg:py-4 border-2 border-black text-black bg-white text-sm sm:text-base lg:text-lg font-semibold rounded-full hover:bg-gray-100 transition-colors fade-in-load">
      Pricing
    </button>
  </Link>
  <div className="flex justify-center mt-8 fade-in-load">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 sm:h-8 sm:w-8 lg:h-10 lg:w-10 text-white animate-bounce"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
    </svg>
  </div>
</div>


{/* Icons with Stars */}
<div className="bg-[#f5f2ec] py-8 px-4 mt-0 fade-in-load">
  {/* Single Set of Stars */}
  <div className="flex items-center justify-center space-x-1 mb-4">
    {[...Array(5)].map((_, index) => (
      <FaStar
        key={index}
        className={`text-yellow-400 text-lg ${index < 4 ? "" : "opacity-70"}`}
      />
    ))}
    <span className="text-gray-600 text-sm pl-2">4.8</span>
  </div>
  
{/* Collaboration Text */}
<p className="text-center text-gray-700 text-sm sm:text-base mb-6">
  Collaborating with schools throughout the UK.
</p>

{/* Icons Section */}
{/* Icons Section */}
<div className="flex flex-wrap justify-center items-center space-x-4">
  {[
    {
      src: "https://github.com/eddiebullock/landing-page-assets/blob/main/logo.png?raw=true",
      alt: "Logo 1",
      className: "w-16 h-16 black-filter",
    },
    {
      src: "https://github.com/eddiebullock/landing-page-assets/blob/main/hartismere.png?raw=true",
      alt: "Hartismere Logo",
      className: "w-16 h-16",
    },
    {
      src: "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-07%20at%2010.12.26.png?raw=true",
      alt: "Screenshot",
      className: "w-16 h-16",
    },
    {
      src: "https://github.com/eddiebullock/landing-page-assets/blob/main/KLS-Logo-v2.png?raw=true",
      alt: "KLS Logo",
      className: "w-16 h-16 black-filter",
    },
    {
      src: "https://github.com/eddiebullock/landing-page-assets/blob/main/logo@2x.png?raw=true",
      alt: "Logo 2x",
      className: "w-16 h-16 black-filter",
    },
    {
      src: "https://raw.githubusercontent.com/eddiebullock/landing-page-assets/94d267ae000eb9bf02bb8be0696d0b8f1c98f228/marriottslogo.svg",
      alt: "Marriotts Logo",
      className: "w-16 h-16", // No black filter here
    },
    {
      src: "https://github.com/eddiebullock/landing-page-assets/blob/main/CASTlogo.png?raw=true",
      alt: "CAST Logo",
      className: "w-16 h-16", // Added the CAST logo here
    },
  ].map((img, index) => (
    <div key={index} className="flex flex-col items-center">
      <img
        src={img.src}
        alt={img.alt}
        className={`${img.className} object-contain`}
      />
    </div>
  ))}
</div>
</div>

{/* Monthly Mental Health eLearning Section */}
<div className="bg-[#f5f2ec] py-12 px-6 sm:px-12 lg:px-20">
  <div className="max-w-2xl lg:max-w-4xl mx-auto text-center mb-8 fade-in">
    <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-gray-800 mb-4">
      Mental Health Education Videos
    </h2>
    <p className="text-sm sm:text-base lg:text-lg text-gray-600">
      "A weight off the shoulders of my teachers" - Reduce your teachers' workload and equip your students with a foundational understanding of the science underlying mental health. Utilise our educational videos designed to fit seamlessly into the school curriculum. Our videos spark meaningful discussions and promote emotional regulation, resilience, heightened awareness, and the early identification of mental health challenges.
    </p>

      {/* Images Section */}
      <div className="flex flex-wrap justify-center gap-6 mt-8">
        {[
          "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-20%20at%2011.48.50.png?raw=true",
          "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-20%20at%2011.49.25.png?raw=true",
          "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-20%20at%2011.49.46.png?raw=true",
        ].map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`eLearning example ${index + 1}`}
            className="w-36 h-36 sm:w-44 sm:h-44 lg:w-52 lg:h-52 object-cover rounded-xl shadow-md"
          />
        ))}
      </div>

      <p className="text-sm sm:text-base lg:text-lg text-gray-600 mt-6">
        <strong>Want to try out our videos in your classrooms?</strong> Get in touch requesting them and we'll send them to you for <strong>FREE</strong>.
      </p>
      <div className="mt-6">
        <Link
          to="/contact"
          className="py-2 px-6 sm:py-3 sm:px-8 lg:py-4 lg:px-12 bg-[#4b8f4b] text-white rounded-full text-sm sm:text-base lg:text-lg hover:bg-[#5fa55f] transition-colors"
        >
          Get in Touch
        </Link>
      </div>
    </div>

  {/* Mental Health Assemblies Section */}
  <div className="bg-[#f5f2ec] py-12 px-6 sm:px-12 lg:px-20">
    <div className="max-w-2xl lg:max-w-4xl mx-auto text-center fade-in">
      <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-black mb-4">
        Mental Health Assemblies
      </h2>
      <p className="text-sm sm:text-base lg:text-lg text-gray-600">
        Engaging, informative, and science-backed talks tailored for young audiences. Explore the brain's role in mental health and how it impacts everyday life.
      </p>
    </div>
  </div>

{/* Three Curved Boxes with Images */}
<div className="flex flex-col md:flex-row justify-center items-center space-y-6 md:space-y-0 md:space-x-6 mt-0 px-4 sm:px-8 lg:px-16">
  {[
    {
      title: "The Anterior Cingulate Cortex",
      desc: "Doing difficult things is good for us",
      imgSrc: "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-15%20at%2023.12.22.png?raw=true",
    },
    {
      title: "The Prefrontal Cortex",
      desc: "Why brain development means that young people are more likely to be impulsive",
      imgSrc: "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-20%20at%2009.09.04.png?raw=true",
    },
  ].map((box, index) => (
    <div
      key={index}
      className="w-full md:w-1/3 bg-white rounded-3xl shadow-lg p-4 sm:p-6 text-center relative fade-in transform transition-transform duration-300 hover:scale-105"
    >
      <h4 className="text-xl sm:text-2xl lg:text-3xl font-semibold text-black mt-4">{box.title}</h4>
      <p className="text-sm sm:text-base lg:text-lg text-gray-600 mb-4">{box.desc}</p>
      <div className="relative">
        <img
          src={box.imgSrc}
          alt={`Visual for section ${index + 1}`}
          className="w-full h-[200px] sm:h-[250px] lg:h-[300px] object-cover rounded-2xl"
        />
        <div
          className="absolute top-3 left-3 bg-[#4b8f4b] text-white text-xs sm:text-sm lg:text-base font-semibold rounded-full px-3 sm:px-4 lg:px-6 py-2 flex items-center space-x-2 transition-transform duration-200 hover:bg-[#5fa55f] hover:scale-110 shadow-lg"
          style={{
            cursor: "pointer",
          }}
        >
          <FaPlay className="text-sm sm:text-base lg:text-lg" />
          <Link to="/videos" className="text-white">
            Watch
          </Link>
        </div>
      </div>
    </div>
  ))}
</div>
</div>

{/* Book an Assembly Button */}
<div className="text-center mt-6 sm:mt-8">
  <Link
    to="/join" // Navigate to the Join.js route
    className="py-2 px-6 sm:py-3 sm:px-8 lg:py-4 lg:px-12 bg-[#4b8f4b] text-white rounded-full text-sm sm:text-lg lg:text-xl mr-2 sm:mr-4 hover:bg-[#5fa55f] transition-colors"
  >
    Book an Assembly
  </Link>
</div>

{/* Teacher Community Section */}
<div className="flex flex-col md:flex-row items-start md:items-center bg-[#f5f2ec] py-8 sm:py-12 px-4 sm:px-8 lg:px-16 mt-12 sm:mt-16 fade-in">
  {/* Text Section */}
  <div className="md:w-1/2 text-left mb-6 md:mb-0">
    <h3 className="text-2xl sm:text-3xl lg:text-4xl font-semibold text-black">
      Join Our Teacher Community
    </h3>
    <p className="text-sm sm:text-base lg:text-lg text-gray-600 mt-4">
      Our mission is to encourage teachers and school practitioners to commit long-term to improving young people’s mental health. We use this community to better understand schools and the mental health challenges they face so that we can adapt our videos to address topics suggested by the teachers in our community.
    </p>
  </div>
  {/* Image Section */}
  <div className="md:w-1/2 flex flex-col items-center justify-center mt-6 md:mt-0 md:ml-8">
    <div className="bg-white rounded-3xl shadow-lg p-4 sm:p-6 lg:p-8">
      <img
        src="https://raw.githubusercontent.com/eddiebullock/landing-page-assets/ab45bc4b2fb1a5f512af631d9f39856db149791d/The-Complete-Guide-to-LinkedIn-Groups-V2-01.svg"
        alt="Teacher Community"
        className="w-full h-[200px] sm:h-[250px] lg:h-[300px] object-cover rounded-2xl"
      />
    </div>
  </div>
</div>

       {/* Premium Substack Subscription with Cream Background and Curved Box */}
<div className="bg-[#f5f2ec] py-8 sm:py-12 lg:py-16 px-4 sm:px-8 lg:px-16 fade-in">
  <div className="bg-white rounded-3xl shadow-lg p-6 sm:p-10 lg:p-12 text-center">
    <h3 className="text-2xl sm:text-3xl lg:text-4xl font-semibold text-black">
      Daily Psychoeducation for Your Students
    </h3>
    <p className="text-sm sm:text-base lg:text-lg text-gray-600 mt-4">
      Empower your students with daily mental health literacy through our premium Substack subscription, featuring expertly curated articles to support their well-being.
    </p>
    <div className="bg-white rounded-3xl p-4 sm:p-6 lg:p-8 mt-6">
      <Swiper
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={1.5} // Dynamically adjusts viewable slides
        breakpoints={{
          640: { slidesPerView: 2.5 }, // Tablets
          1024: { slidesPerView: 3.5 }, // Desktops
        }}
        spaceBetween={-20}
        simulateTouch={true}
        mousewheel={{ enabled: true, forceToAxis: true }}
        coverflowEffect={{
          rotate: 0,
          stretch: -10,
          depth: 100,
          modifier: 1.2,
          slideShadows: false,
        }}
        pagination={{ clickable: true }}
        modules={[Pagination, EffectCoverflow, Mousewheel]}
        className="w-full h-[150px] sm:h-[200px] lg:h-[250px] rounded-lg mx-auto mt-6"
      >
        {[
          "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-15%20at%2016.21.53.png?raw=true",
          "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-01%20at%2023.40.10.png?raw=true",
          "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-01%20at%2023.40.38.png?raw=true",
          "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-01%20at%2023.41.00.png?raw=true",
          "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-15%20at%2016.20.57.png?raw=true",
          "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-04%20at%2022.47.01.png?raw=true",
          "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-04%20at%2022.48.10.png?raw=true",
          "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-15%20at%2016.19.37.png?raw=true",
        ].map((src, index) => (
          <SwiperSlide key={index}>
            <img
              src={src}
              alt={`Slide ${index + 1}`}
              className="w-full h-[120px] sm:h-[200px] lg:h-[250px] object-contain rounded-lg"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  </div>
</div>

{/* Watch Button Section */}
<div className="flex flex-col sm:flex-row justify-center items-center mt-12 sm:mt-16 mb-0 space-y-4 sm:space-y-0 sm:space-x-4">
  <Link
    to="/videos" // Navigate to VideoPage.js
    className="py-2 px-6 sm:py-3 sm:px-8 lg:py-4 lg:px-12 bg-[#4b8f4b] text-white rounded-full text-sm sm:text-lg lg:text-xl font-bold flex items-center space-x-2 hover:bg-[#5fa55f] transition-colors"
  >
    <FaPlay className="text-white" />
    <span>Watch</span>
  </Link>
  <p className="text-sm sm:text-base lg:text-xl font-semibold text-gray-800 text-center sm:text-left">
    Watch some clips from our video assemblies
  </p>
</div>
      </section>
    </div>
  );
}

export default Services;
