import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

function Header() {
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const isActive = (path) => location.pathname === path;

  // Check if the current page is "Our Impact"
  const isTransparentHeader = location.pathname === "/" && !scrolled;

  // Specific styles for "Our Impact" section
  const isOurImpact = location.pathname === "/";

  return (
    <header
      className={`fixed top-0 left-0 w-full z-50 transition-colors duration-300 ${
        isTransparentHeader ? "bg-transparent" : "bg-white"
      }`}
      style={{ padding: "5px 0" }}
    >
      <nav className="flex justify-between items-center px-4 md:px-6">
        {/* Logo Section */}
        <div className="flex items-center">
          <img
            src="https://raw.githubusercontent.com/eddiebullock/landing-page-assets/f0401b898b7a40c5c8e96ae78ab15d7422333d9a/4IrlTa01.svg"
            alt="Logo"
            className="w-12 h-12 mr-2 relative"
            style={{ top: "2px", right: "-4px" }}
          />
          <Link
            to="/"
            className={`text-lg sm:text-xl font-bold ${
              isOurImpact ? "text-black" : isTransparentHeader ? "text-white" : "text-black"
            }`}
          >
            TheMentalHealthProgram
          </Link>
        </div>

        {/* Desktop Menu */}
        <div className="hidden lg:flex space-x-3 text-xs font-bold">
          {[
            { to: "/", label: "Our Impact" },
            { to: "/services", label: "Services" },
            { to: "/who-we-are", label: "Who We Are" },
            { to: "/contact", label: "Contact" },
          ].map((link) => (
            <Link
              key={link.to}
              to={link.to}
              className={`relative px-2 py-1 ${
                isOurImpact
                  ? "text-gray-500"
                  : isTransparentHeader
                  ? "text-white"
                  : "text-gray-700"
              }`}
            >
              {isActive(link.to) && (
                <span className="absolute left-0 top-1/2 transform -translate-y-1/2 h-4 w-1 bg-[#4b8f4b] rounded-full"></span>
              )}
              {link.label}
            </Link>
          ))}
          <Link
            to="/join"
            className="py-1 px-3 bg-[#4b8f4b] text-white rounded-full text-xs hover:bg-[#5fa55f] flex items-center justify-center"
          >
            Pricing
          </Link>
          <Link
            to="/weekly-videos"
            className="py-1 px-3 border border-black text-black rounded-full text-xs bg-white hover:bg-gray-100 flex items-center justify-center"
          >
            Subscriber's Area
          </Link>
        </div>

        {/* Mobile Hamburger Menu */}
        <div className="lg:hidden flex items-center">
          <button onClick={toggleMenu} className="focus:outline-none">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>

        {/* Mobile Menu */}
        {menuOpen && (
          <div
            ref={menuRef}
            className="lg:hidden fixed top-0 left-0 w-3/4 h-screen bg-[#f5f2ec] px-4 py-4 z-40 shadow-lg"
          >
            <button
              onClick={toggleMenu}
              className="text-right text-black font-bold text-xl mb-4 focus:outline-none"
            >
              &times;
            </button>
            {[
              { to: "/", label: "Our Impact" },
              { to: "/services", label: "Services" },
              { to: "/who-we-are", label: "Who We Are" },
              { to: "/contact", label: "Contact" },
            ].map((link) => (
              <Link
                key={link.to}
                to={link.to}
                onClick={toggleMenu}
                className={`block ${
                  isOurImpact ? "text-gray-500" : "text-black"
                } hover:text-gray-600 text-base relative py-2`}
              >
                {isActive(link.to) && (
                  <span className="absolute left-0 top-1/2 transform -translate-y-1/2 h-4 w-1 bg-[#4b8f4b] rounded-full"></span>
                )}
                {link.label}
              </Link>
            ))}
            <Link
              to="/join"
              onClick={toggleMenu}
              className="block py-2 px-3 bg-[#4b8f4b] text-white rounded-full hover:bg-[#5fa55f] text-base text-center"
            >
              Pricing
            </Link>
            <Link
              to="/weekly-videos"
              onClick={toggleMenu}
              className="block py-2 px-3 border border-black text-black rounded-full bg-white hover:bg-gray-100 text-base text-center"
            >
              Subscriber's Area
            </Link>
          </div>
        )}
      </nav>
    </header>
  );
}

export default Header;
