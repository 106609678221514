import React, { useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa"; // Import improved tick icon
import "./Join.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate for routing

function Join() {
  const navigate = useNavigate(); // Initialize navigate for programmatic routing

  useEffect(() => {
    // Add the fade-in effect to elements when component mounts
    const elements = document.querySelectorAll(".fade-in-box");
    elements.forEach((el) => el.classList.add("fade-in-visible"));
  }, []);

  return (
    <div className="bg-[#f5f2ec] py-12 px-4 sm:py-16 sm:px-8 mt-12 sm:mt-16 fade-in-box">
      <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-center text-gray-800 mb-6 sm:mb-10">
        Pricing
      </h2>
      <p className="text-sm sm:text-base lg:text-lg text-center text-gray-600 mb-8 sm:mb-16">
        Explore our comprehensive mental health education course.
      </p>

      <div className="flex flex-col justify-center items-center gap-6 sm:gap-8">
        {/* Course Option */}
        <div className="bg-[#4b8f4b] rounded-xl shadow-lg p-6 sm:p-8 lg:p-10 w-full sm:w-3/4 lg:w-1/2 text-center text-white transition-transform duration-300 transform hover:scale-105">
          <h3 className="text-xl sm:text-2xl lg:text-3xl font-semibold text-white mb-4">
            Introduction to Young People's Mental Health Course
          </h3>
          <p className="text-sm sm:text-base lg:text-lg text-white mb-4">
            A series of videos for your whole school to be played during form
            time, creating discussions around important mental health topics.
          </p>
          <div className="mt-4 sm:mt-6 space-y-3 text-xs sm:text-sm lg:text-base lg:grid lg:grid-cols-2 lg:gap-4">
            <div className="flex items-center">
              <FaCheckCircle className="text-white mr-2 sm:mr-3" size={16} />
              Brain development
            </div>
            <div className="flex items-center">
              <FaCheckCircle className="text-white mr-2 sm:mr-3" size={16} />
              Cognitive development
            </div>
            <div className="flex items-center">
              <FaCheckCircle className="text-white mr-2 sm:mr-3" size={16} />
              Attachment
            </div>
            <div className="flex items-center">
              <FaCheckCircle className="text-white mr-2 sm:mr-3" size={16} />
              What are emotions
            </div>
            <div className="flex items-center">
              <FaCheckCircle className="text-white mr-2 sm:mr-3" size={16} />
              Neurodevelopmental conditions
            </div>
            <div className="flex items-center">
              <FaCheckCircle className="text-white mr-2 sm:mr-3" size={16} />
              What makes you you
            </div>
            <div className="flex items-center">
              <FaCheckCircle className="text-white mr-2 sm:mr-3" size={16} />
              Social development
            </div>
            <div className="flex items-center">
              <FaCheckCircle className="text-white mr-2 sm:mr-3" size={16} />
              How therapy works
            </div>
            <div className="flex items-center">
              <FaCheckCircle className="text-white mr-2 sm:mr-3" size={16} />
              Mental health conditions
            </div>
          </div>
          <p className="text-sm sm:text-base lg:text-lg mt-4">
            Includes two videos per topic: one for Years 7-9 and another for
            Years 10-13.
          </p>
          <p className="text-2xl sm:text-3xl lg:text-4xl font-bold mt-4">
            £49.99 <span className="text-xs sm:text-lg">Pre-order Price</span>
          </p>
          <p className="text-xs sm:text-sm mt-2">
            Price will increase to £89.99 upon release.
          </p>
          <a
            href="https://buy.stripe.com/aEU2bndKw58C4uc003"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-6 sm:mt-8 px-6 sm:px-8 lg:px-12 py-2 sm:py-3 lg:py-4 text-[#4b8f4b] border border-[#4b8f4b] text-sm sm:text-md lg:text-lg font-semibold rounded-full bg-white hover:bg-gray-100 transition-all inline-block"
          >
            Pre-order Now
          </a>
        </div>

        {/* Single Assembly Option */}
        <div className="bg-gray-100 rounded-xl shadow-lg p-6 sm:p-8 lg:p-10 w-full sm:w-3/4 lg:w-1/2 text-center transition-transform duration-300 transform hover:scale-105">
          <h3 className="text-xl sm:text-2xl lg:text-3xl font-semibold text-gray-800 mb-4">
            Single Assembly
          </h3>
          <p className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-900">
            £39.99
          </p>
          <p className="text-xs sm:text-sm text-gray-500">+ Travel Expenses</p>
          <p className="text-sm sm:text-base lg:text-lg text-gray-600 mt-4">
            Perfect for schools wanting a one-time, in-person mental health
            assembly delivered by our experts.
          </p>
          <a
            href="https://buy.stripe.com/5kAg2dgWIdF89Ow8wA"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-6 px-6 sm:px-8 py-2 sm:py-3 lg:py-4 bg-[#4b8f4b] text-white text-sm sm:text-md font-semibold rounded-full hover:bg-[#5fa55f] transition-colors inline-block"
          >
            Book Assembly
          </a>
        </div>
      </div>

      {/* Get in Touch Section */}
      <div className="flex flex-col sm:flex-row items-center justify-center mt-8 sm:mt-12 gap-4">
        <p className="text-sm sm:text-base text-gray-600">Got questions?</p>
        <button
          onClick={() => navigate("/contact")} // Navigate to Contact.js
          className="px-6 sm:px-8 py-2 sm:py-3 bg-[#4b8f4b] text-white text-sm sm:text-md font-semibold rounded-full hover:bg-[#5fa55f] transition-colors"
        >
          Get in Touch
        </button>
      </div>
    </div>
  );
}

export default Join;
