// src/firebase.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCwL9N-HER8b9YDh1O6bJyyJWBYCv1v56g",
  authDomain: "mhp-email-collector.firebaseapp.com",
  projectId: "mhp-email-collector",
  storageBucket: "mhp-email-collector.appspot.com", // Corrected storage URL
  messagingSenderId: "227424767953",
  appId: "1:227424767953:web:6287cc6ebd92920a85daf0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
