import React, { useState, useEffect } from "react";
import { collection, addDoc, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import { db } from "../Firebase"; // Import your Firebase configuration

function WeeklyVideos() {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState("");

  const commentsCollectionRef = collection(db, "comments");

  // Load comments from Firestore in real-time
  useEffect(() => {
    const unsubscribe = onSnapshot(commentsCollectionRef, (snapshot) => {
      const fetchedComments = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setComments(fetchedComments);
      console.log("Fetched comments:", fetchedComments); // Debug log
    });
  
    return () => unsubscribe(); // Clean up the listener when the component unmounts
  }, [commentsCollectionRef]); // Add commentsCollectionRef here  
  

  const handleAddComment = async () => {
    if (newComment.trim()) {
      try {
        await addDoc(commentsCollectionRef, {
          text: newComment,
          timestamp: new Date(),
        });
        setNewComment("");
      } catch (error) {
        console.error("Error adding comment:", error);
      }
    }
  };

  const handleDeleteComment = async (id) => {
    try {
      await deleteDoc(doc(db, "comments", id));
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === "MHP2024") {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password. Please try again.");
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="bg-white p-6 rounded shadow-lg w-96">
          <h1 className="text-2xl font-bold mb-4 text-center">Subscriber's Area</h1>
          <p className="text-gray-700 mb-4 text-center">Please enter the password to access this page.</p>
          <form onSubmit={handlePasswordSubmit}>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              className="w-full p-2 border border-gray-300 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-[#4b8f4b]"
              required
            />
            <button
              type="submit"
              className="w-full px-4 py-2 bg-[#4b8f4b] text-white font-semibold rounded hover:bg-[#5fa55f] transition-colors"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-8 mt-12">
      <h1 className="text-4xl font-bold mb-6">Subscriber's Area</h1>

      {/* Video Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div>
          <video controls className="w-full rounded-lg shadow-lg">
            <source src="https://path-to-video1.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div>
          <video controls className="w-full rounded-lg shadow-lg">
            <source src="https://path-to-video2.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      {/* Comments Section */}
      <div>
        <h2 className="text-2xl font-semibold mb-4">Comments</h2>
        <textarea
          className="w-full p-2 border border-gray-300 rounded mb-4"
          rows="3"
          placeholder="Add your comment"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        ></textarea>
        <button
          onClick={handleAddComment}
          className="px-4 py-2 bg-[#4b8f4b] text-white font-semibold rounded hover:bg-[#5fa55f] transition-colors"
        >
          Post Comment
        </button>
        <ul className="mt-4 space-y-2">
  {comments.length === 0 ? (
    <p className="text-gray-500">No comments yet. Be the first to comment!</p>
  ) : (
    comments.map((comment) => (
      <li
        key={comment.id}
        className="p-2 border border-gray-200 rounded flex justify-between items-center"
      >
        <span>{comment.text}</span>
        <button
          onClick={() => handleDeleteComment(comment.id)}
          className="text-red-500 hover:underline text-sm"
        >
          Delete
        </button>
      </li>
    ))
  )}
</ul>
      </div>
    </div>
  );
}

export default WeeklyVideos;
