import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import ScrollToTop from "./Components/ScrollToTop";
import OurImpact from "./Components/OurImpact";
import Services from "./Components/Services";
import Join from "./Components/Join";
import Contact from "./Components/Contact";
import VideoPage from "./Components/VideoPage";
import WhoWeAre from "./Components/WhoWeAre";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TermsOfService from "./Components/TermsOfService";
import WeeklyVideos from "./Components/WeeklyVideos";

ReactGA.initialize("G-HDBLBYJYDX");

// Hook for tracking page views with Google Analytics
function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <div className="min-h-screen">
        {/* Tracks page views for Google Analytics */}
        <PageViewsTracker />
        <Routes>
          {/* Define all routes for your app */}
          <Route path="/" element={<OurImpact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/join" element={<Join />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/who-we-are" element={<WhoWeAre />} />
          <Route path="/videos" element={<VideoPage />} />
          <Route path="/weekly-videos" element={<WeeklyVideos />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

function PageViewsTracker() {
  usePageViews();
  return null;
}

export default App;
