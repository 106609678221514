import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Mousewheel } from "swiper/modules";

function ImageSlider() {
  return (
    <Swiper
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={3.5} // Default for larger screens
      spaceBetween={-20} // Adjust spacing for a close-together look
      loop={true}
      pagination={{ clickable: true }}
      modules={[Pagination, Mousewheel]}
      mousewheel={{ forceToAxis: true, invert: false }}
      className="w-full h-[60vh] max-h-[400px] rounded-lg"
      breakpoints={{
        // Adjust for iPhone SE and smaller screens
        320: {
          slidesPerView: 1.5, // Show fewer images
          spaceBetween: 10,   // Increase spacing between images
          height: "50vh",     // Make slider taller
        },
        // Adjust for medium screens
        768: {
          slidesPerView: 2.5,
          spaceBetween: 10,
        },
        // Default for larger screens
        1024: {
          slidesPerView: 3.5,
          spaceBetween: -20,
        },
      }}
    >
      {/* Slides with Larger Enlargement Effect */}
      {[
        "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-02%20at%2013.39.03.png?raw=true",
        "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-02%20at%2013.38.49.png?raw=true",
        "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-02%20at%2013.38.34.png?raw=true",
        "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-02%20at%2013.38.14.png?raw=true",
        "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-02%20at%2013.37.52.png?raw=true",
        "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-02%20at%2013.37.28.png?raw=true",
        "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-02%20at%2013.37.06.png?raw=true",
        "https://github.com/eddiebullock/landing-page-assets/blob/main/Screenshot%202024-11-02%20at%2013.36.39.png?raw=true",
      ].map((src, index) => (
        <SwiperSlide key={index}>
          <img
            src={src}
            alt={`Slide ${index + 1}`}
            className="w-auto h-full object-contain rounded-lg transition-transform duration-500 hover:scale-125"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default ImageSlider;
