import React, { useState } from "react";
import { FaLinkedin } from "react-icons/fa"; // Import LinkedIn icon

function Contact() {
  const [submitted, setSubmitted] = useState(false); // State to track form submission

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    // Set the form action to submit through Formspree
    fetch("https://formspree.io/f/manyoklz", {
      method: "POST",
      body: new FormData(e.target),
      headers: { Accept: "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          setSubmitted(true); // Set the submitted state to true
          e.target.reset(); // Reset the form fields
        } else {
          alert("There was an error submitting the form. Please try again.");
        }
      })
      .catch(() => alert("There was an error submitting the form. Please try again."));
  };

  return (
    <div className="min-h-screen bg-[#f5f2ec] py-12 sm:py-16 px-4 sm:px-8 flex flex-col items-center">
      {/* Title Section */}
      <div className="max-w-lg sm:max-w-3xl text-center mb-8 sm:mb-12">
        <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-gray-800 mt-6 sm:mt-12 mb-4">
          Get in Touch
        </h2>
        <p className="text-sm sm:text-base lg:text-lg text-gray-700">
          We’d love to hear from you. Whether you have questions, feedback, or want to collaborate, please feel free to reach out.
        </p>
      </div>

      {/* Contact Form Section */}
      <div className="max-w-md sm:max-w-3xl w-full bg-white rounded-3xl shadow-lg p-6 sm:p-8 lg:p-10 mb-8 sm:mb-12">
        {submitted ? (
          <p className="text-center text-lg sm:text-xl text-green-700 font-semibold">
            Thanks for your message! We’ll be in touch as soon as possible.
          </p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="mb-4 sm:mb-6">
              <label htmlFor="name" className="block text-sm sm:text-lg font-medium text-gray-800 mb-2">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full px-4 py-2 sm:py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#4b8f4b]"
                placeholder="Your name"
                required
              />
            </div>

            <div className="mb-4 sm:mb-6">
              <label htmlFor="email" className="block text-sm sm:text-lg font-medium text-gray-800 mb-2">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full px-4 py-2 sm:py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#4b8f4b]"
                placeholder="Your email"
                required
              />
            </div>

            <div className="mb-4 sm:mb-6">
              <label htmlFor="message" className="block text-sm sm:text-lg font-medium text-gray-800 mb-2">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows="4"
                className="w-full px-4 py-2 sm:py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#4b8f4b]"
                placeholder="Write your message here"
                required
              ></textarea>
            </div>

            <button
              type="submit"
              className="w-full py-2 sm:py-3 lg:py-4 bg-[#4b8f4b] text-white font-semibold rounded-lg hover:bg-[#3a6e3a] transition-colors"
            >
              Send Message
            </button>
          </form>
        )}
      </div>

      {/* Contact Information with LinkedIn */}
      <div className="max-w-lg sm:max-w-3xl text-center text-gray-700 mb-8 sm:mb-12 flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4">
        <p className="text-sm sm:text-base lg:text-lg">
          <strong>Email:</strong> contact@mentalhealthprogram.com
        </p>
        <a
          href="https://www.linkedin.com/company/the-mental-health-program/about/?viewAsMember=true" // Replace with your LinkedIn profile URL
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-700 hover:text-blue-500 flex items-center"
          title="LinkedIn Profile"
        >
          <FaLinkedin className="w-6 h-6 sm:w-8 sm:h-8 ml-2" />
        </a>
      </div>

      {/* SVG at the Bottom */}
      <div className="flex justify-center mt-4">
        <img
          src="https://raw.githubusercontent.com/eddiebullock/landing-page-assets/a093185a5bd755544767d5b45d1bb2574ec079c4/4IrlTa01.svg"
          alt="Decorative SVG"
          className="w-40 sm:w-60 h-auto"
        />
      </div>
    </div>
  );
}

export default Contact;
