import React, { useEffect } from "react";
import AttendanceSlider from "./AttendanceSlider";
import ImageSlider from "./ImageSlider";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./WhyYouNeedIt.css"; // Import CSS for animations

function WhyYouNeedIt() {
  const navigate = useNavigate();

  useEffect(() => {
    const fadeElements = document.querySelectorAll(".fade-in-left");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.05 }
    );

    fadeElements.forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, []);

  return (
    <section className="why-you-need-it bg-white py-10 sm:py-16 px-4 sm:px-8 text-center">
      {/* New "What We Offer" Section */}
      <div className="max-w-xl mx-auto mb-8 sm:mb-10 text-center">
        <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-4">What We Offer</h2>
        <p className="text-sm sm:text-base text-gray-600">
          We provide an introduction to young peoples mental health course and in-person assemblies, informed by science. We deliver cutting-edge research to help students improve their mental health. Our goal is to reduce the mental health burden in schools through impactful psychoeducation.
        </p>

        {/* Pricing Button */}
        <div className="flex justify-center mt-6">
          <button
            onClick={() => navigate("/join")} // Navigate to the pricing page
            className="flex items-center justify-center py-2 px-6 sm:py-3 sm:px-10 bg-[#4b8f4b] text-white rounded-full text-base sm:text-lg hover:bg-[#5fa55f]"
          >
            Pricing
          </button>
        </div>
      </div>

      {/* Attendance Slider Section */}
      <div className="my-10 sm:my-16 max-w-full mx-auto bg-[#f5f2ec] rounded-3xl shadow-lg p-6 sm:p-10 fade-in-left">
        <AttendanceSlider />
      </div>

      {/* "Our Impact" Section */}
      <div className="max-w-xl mx-auto mt-10 sm:mt-16">
        <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-4">Our Impact</h2>
        <p className="text-sm sm:text-base text-gray-600">
          Demand for mental health provision has skyrocketed, and schools are substantially under-resourced. Our mission is to enable schools to leverage psychoeducation to attenuate this demand.
        </p>
      </div>

      {/* Statistics Section */}
      <div className="max-w-4xl mx-auto mt-16 sm:mt-20 mb-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4">
        {[
          {
            value: "50%",
            description:
              "of mental health issues are established by age 14, and 75% by age 24.",
            source: "Mental Health Foundation",
          },
          {
            value: "10%",
            description:
              "of children and young people (ages 5 to 16) have a clinically diagnosable mental health condition.",
            source: "YoungMinds",
          },
          {
            value: "70%",
            description:
              "of children and adolescents who experience mental health problems haven’t received early and effective interventions.",
            source: "Children’s Commissioner",
          },
        ].map((stat, index) => (
          <div
            key={index}
            className="p-6 sm:p-10 bg-gradient-to-b from-gray-800 to-black rounded-2xl shadow-lg border border-gray-700 fade-in-left"
          >
            <span className="block text-4xl sm:text-6xl font-bold text-white mb-2">
              {stat.value}
            </span>
            <p className="text-xs sm:text-sm text-gray-100">{stat.description}</p>
            <p className="mt-2 text-xs text-gray-400">
              <sup>{index + 2}</sup> Source: {stat.source}
            </p>
          </div>
        ))}
      </div>

      {/* Title Above List */}
      <div className="mt-12 mb-4 sm:mb-6">
        <h2 className="text-3xl sm:text-4xl font-semibold text-gray-800">
          Mental Health Literacy Leads To:
        </h2>
      </div>

      {/* Psychoeducation Benefits Section */}
      <div className="max-w-4xl mx-auto mb-20 sm:mb-32">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 text-left">
          {[
            "Earlier identification and intervention",
            "Increased resilience",
            "Enhanced emotional regulation",
            "Reduced stigma around mental health",
            "Improved social skills and relationships",
            "Better coping strategies",
          ].map((benefit, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg p-4 sm:p-6 flex items-start transform transition-transform duration-300 ease-in-out fade-in-left"
            >
              <FaCheckCircle className="text-green-600 mt-1 mr-3" size={24} />
              <p className="text-xs sm:text-sm font-medium text-gray-700">
                {benefit}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Image Slider Section */}
      <div className="bg-[#f5f2ec] pt-10 sm:pt-16 pb-16 sm:pb-32 text-center w-screen relative -mx-4 sm:-mx-8 px-4 sm:px-8">
        <div className="max-w-xl sm:max-w-4xl mx-auto">
          <h3 className="text-2xl sm:text-3xl font-semibold text-black mb-4">
            The Evidence Base for Psychoeducation is Robust:
          </h3>
          <p className="text-sm sm:text-base text-gray-700 mb-6">
            Studies show that psychoeducation can improve young people’s understanding of mental health, reduce stigma, and increase their willingness to seek support. Through knowledge and awareness, young individuals can build resilience, develop coping strategies, and foster healthier mindsets.
          </p>
        </div>
        <div className="mt-8 sm:mt-12 rounded-3xl overflow-hidden">
          <ImageSlider />
        </div>
      </div>
    </section>
  );
}

export default WhyYouNeedIt;
