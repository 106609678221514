import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="container mx-auto p-8 text-gray-800">
      <h1 className="text-4xl font-bold mb-6">Privacy Policy</h1>

      <h2 className="text-2xl font-semibold mt-6 mb-4">1. Introduction</h2>
      <p className="text-lg mb-4">
        The Mental Health Program Ltd (“we,” “our,” or “us”) is committed to protecting your privacy and ensuring the security of your personal information.
        This Privacy Policy explains what information we collect, how we use it, and your rights regarding your data.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">2. Information We Collect</h2>
      <p className="text-lg mb-4">
        We may collect the following information:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Personal Information: Name, email address, school or institutional affiliation, and payment details when you register for our services.</li>
        <li>Usage Data: Information about how you interact with our website, e-learning courses, and other content.</li>
        <li>Communication Data: Messages or inquiries sent to us through email or forms on our website.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">3. How We Use Your Information</h2>
      <p className="text-lg mb-4">
        Your information is used for the following purposes:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>To provide and improve our services.</li>
        <li>To communicate updates, notifications, and customer support.</li>
        <li>To personalize your experience and adapt content to your needs.</li>
        <li>To comply with legal obligations, such as tax or payment processing requirements.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">4. How We Protect Your Data</h2>
      <p className="text-lg mb-4">
        We implement strict security measures to protect your personal information from unauthorized access, alteration, or destruction.
        However, no online platform is completely secure, and we cannot guarantee the absolute security of your data.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">5. Sharing Your Information</h2>
      <p className="text-lg mb-4">
        We do not sell or rent your information. We may share it only under the following circumstances:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Service Providers: With trusted third parties who assist us in delivering our services (e.g., payment processors, hosting providers).</li>
        <li>Legal Requirements: When required by law, or to protect our rights, property, or safety.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">6. Data Retention</h2>
      <p className="text-lg mb-4">
        We retain your personal information only as long as necessary to provide our services, comply with legal obligations, or resolve disputes.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">7. Cookies and Tracking</h2>
      <p className="text-lg mb-4">
        Our website may use cookies to improve user experience and collect analytics data.
        You can manage cookies through your browser settings.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">8. Your Rights</h2>
      <p className="text-lg mb-4">
        Under applicable laws, you may have the right to:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Access the personal data we hold about you.</li>
        <li>Request corrections to inaccurate data.</li>
        <li>Request deletion of your data, subject to certain legal or contractual obligations.</li>
        <li>Opt out of marketing communications by following the instructions in our emails or contacting us directly.</li>
      </ul>
      <p className="text-lg mb-4">
        To exercise these rights, contact us at <a href="mailto:contact@mentalhealthprogram.co.uk" className="text-blue-500 underline">contact@mentalhealthprogram.co.uk</a>.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">9. International Data Transfers</h2>
      <p className="text-lg mb-4">
        If you are accessing our services from outside the UK, your information may be transferred to and processed in countries with different data protection laws.
        By using our services, you consent to such transfers.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">10. Changes to This Privacy Policy</h2>
      <p className="text-lg mb-4">
        We may update this Privacy Policy from time to time. The revised version will be posted on our website with an updated “Effective Date.” We encourage you to review the Privacy Policy periodically.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">11. Contact Us</h2>
      <p className="text-lg mb-4">
        If you have questions or concerns about this Privacy Policy or our data practices, please contact us:
      </p>
      <p className="text-lg">
        Email: <a href="mailto:contact@mentalhealthprogram.co.uk" className="text-blue-500 underline">contact@mentalhealthprogram.co.uk</a>
      </p>
    </div>
  );
}

export default PrivacyPolicy;
