import React, { useState } from "react";
import { FaCalendarAlt, FaUsers } from "react-icons/fa"; // Import calendar and users icons

function AttendanceSlider() {
  const [capacity, setCapacity] = useState(18); // initial value
  const missedDays = Math.round((capacity * 15) / 18); // calculate missed days
  const studentsWithMHProblem = Math.round(capacity * 0.2); // calculate students with mental health problem

  const handleSliderChange = (e) => {
    setCapacity(Number(e.target.value));
  };

  return (
    <div className="attendance-slider text-center my-8">
      <h3 className="text-3xl font-semibold mb-4">
        How Much Could The Mental Health Program Save Your School?
      </h3>
      <p>Adjust school capacity to see estimated days missed and mental health statistics.</p>

      {/* Slider container */}
      <div className="relative w-full max-w-xl mx-auto mt-8">
        {/* Static Capacity value above the slider */}
        <div className="font-bold text-green-700 text-4xl mb-4">{capacity} students</div>

        {/* Slider with full width */}
        <input
          type="range"
          min="18"
          max="2000"
          value={capacity}
          onChange={handleSliderChange}
          className="w-full h-2 bg-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-green-700"
          style={{
            appearance: "none",
            background: `linear-gradient(to right, #4b8f4b 0%, #4b8f4b ${
              ((capacity - 18) / 1982) * 100
            }%, #d1d5db ${(capacity - 18) / 1982 * 100}% , #d1d5db 100%)`,
          }}
        />

        {/* Custom Thumb Style */}
        <style>
          {`
            input[type='range']::-webkit-slider-thumb {
              appearance: none;
              width: 32px; /* Larger thumb */
              height: 32px;
              background: #4b8f4b; /* Green color for visibility */
              border-radius: 50%;
              border: 2px solid #ffffff; /* White border for contrast */
              cursor: pointer;
              box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
            }
            input[type='range']::-moz-range-thumb {
              width: 32px;
              height: 32px;
              background: #4b8f4b;
              border-radius: 50%;
              border: 2px solid #ffffff;
              cursor: pointer;
              box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
            }
          `}
        </style>

        {/* Statistics below the slider with icons */}
        <div className="mt-6 flex justify-around text-lg font-bold text-gray-700">
          <div className="text-center">
            <FaCalendarAlt className="text-green-700 mb-2" size={28} /> {/* Calendar icon */}
            <span className="block text-6xl md:text-5xl sm:text-4xl text-gray-800">
              {missedDays}
            </span>
            <p className="text-lg sm:text-sm text-gray-600">days missed per term</p>
          </div>
          <div className="text-center">
            <FaUsers className="text-green-700 mb-2" size={28} /> {/* Group of people icon */}
            <span className="block text-6xl md:text-5xl sm:text-4xl text-gray-800">
              {studentsWithMHProblem}
            </span>
            <p className="text-lg sm:text-sm text-gray-600">
              students with a mental health problem
            </p>
          </div>
        </div>
      </div>

      {/* Reference below the slider */}
      <p className="text-sm text-gray-500 mt-4">1. NHS Digital</p>
    </div>
  );
}

export default AttendanceSlider;
