import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import WhyYouNeedIt from "./WhyYouNeedIt";
import "./OurImpact.css"; // Import for animations

function OurImpact() {
  useEffect(() => {
    const fadeElements = document.querySelectorAll(".fade-in");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.05 }
    );

    fadeElements.forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, []);

  return (
    <div className="bg-[#f5f2ec] min-h-screen pt-24 lg:pt-40 pb-16 lg:pb-20">
      <section className="flex flex-col items-center lg:flex-row lg:items-start gap-6 lg:gap-10 w-full h-auto px-4 sm:px-6 lg:px-10">
        {/* Overlay Section */}
        <div className="flex flex-col justify-center items-start space-y-6 text-black w-full lg:w-1/2">
          <h1 className="text-2xl sm:text-3xl lg:text-6xl font-bold leading-tight fade-in">
            Invest in Your <br /> Students' Mental Health
          </h1>
          <p className="text-base sm:text-lg lg:text-2xl text-gray-600 fade-in">
            Delivering Cutting-Edge Mental Health Research to Schools.
          </p>
          <div className="mt-6 sm:mt-8 space-y-3 sm:space-y-0 sm:space-x-4 fade-in flex flex-col sm:flex-row">
            <Link
              to="/join"
              className="flex items-center justify-center py-2 px-6 sm:py-3 sm:px-10 bg-[#4b8f4b] text-white rounded-full text-base sm:text-lg hover:bg-[#5fa55f]"
            >
              Pricing
            </Link>
            <Link
              to="/services"
              className="flex items-center justify-center py-2 px-6 sm:py-3 sm:px-10 bg-white text-black border border-black rounded-full text-base sm:text-lg hover:bg-gray-200"
            >
              Learn More
            </Link>
          </div>
        </div>

{/* Video and Footer Section */}
<div className="flex flex-col items-center w-full lg:w-1/2 gap-6">
  {/* Video Section */}
  <div className="relative w-full max-w-[320px] sm:max-w-[400px] aspect-video bg-gradient-to-br from-gray-200 to-gray-400 rounded-2xl shadow-xl overflow-hidden flex items-center justify-center hover:scale-105 transition-transform duration-300">
    <iframe
      src="https://player.vimeo.com/video/1031488153?background=1&autoplay=1&loop=1&muted=1"
      className="absolute inset-0 w-full h-full rounded-2xl"
      frameBorder="0"
      allow="autoplay; fullscreen"
      allowFullScreen
      title="Background Video"
    ></iframe>
    {/* Gradient Overlay */}
    <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-30 pointer-events-none"></div>
  </div>

          {/* Footer Section */}
          <div className="flex flex-col items-center text-center">
            <img
              src="https://raw.githubusercontent.com/eddiebullock/landing-page-assets/e859468377a62d3d683e8b5c6aa0cc05f526e2fb/university-of-cambridge-2.svg"
              alt="University of Cambridge"
              className="w-6 sm:w-8 mb-2"
            />
            <p className="text-xs sm:text-sm text-gray-700">
              Developed by Doctoral Researchers
            </p>
          </div>
        </div>
      </section>

      {/* Extra Space Between Footer and Why You Need It */}
      <div className="bg-[#f5f2ec] h-10 sm:h-14 lg:h-16 flex flex-col items-center">
        {/* Scroll Arrow */}
        <svg
          className="h-6 sm:h-8 w-6 sm:w-8 text-gray-600 animate-bounce mt-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19 10l-7 7m0 0l-7-7m7 7V3"
          />
        </svg>
      </div>

      {/* Why You Need It Section */}
      <div className="fade-in">
        <WhyYouNeedIt />
      </div>

{/* Learn More Button */}
<div className="flex justify-center mt-2 fade-in">
  <Link
    to="/services"
    className="flex items-center justify-center py-1 px-4 sm:py-2 sm:px-6 bg-white text-black border border-black rounded-full text-sm sm:text-base hover:bg-gray-100 transition-colors"
  >
    Learn More
  </Link>
</div>

    </div>
  );
}

export default OurImpact;
