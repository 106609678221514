import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for internal routing
import { FaLinkedin } from 'react-icons/fa'; // Import LinkedIn icon

function Footer() {
  return (
    <footer className="bg-[#f5f2ec] text-gray-700 py-12 px-8">
      {/* Horizontal Divider */}
      <div className="border-t border-gray-300 mb-2"></div>
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center text-lg">
        {/* Logo and Company Info on the Left */}
        <div className="flex items-center space-x-2 mb-4 md:mb-0">
          <img
            src="https://raw.githubusercontent.com/eddiebullock/landing-page-assets/f0401b898b7a40c5c8e96ae78ab15d7422333d9a/4IrlTa01.svg"
            alt="Logo"
            className="w-16 h-16"
          />
          <p className="text-sm">© 2024 The Mental Health Program LTD. All rights reserved.</p>
        </div>

        {/* Navigation Links in the Center */}
        <div className="flex items-center space-x-6 mb-4 md:mb-0">
          <Link to="/privacy-policy" className="hover:underline">Privacy Policy</Link>
          <Link to="/terms-of-service" className="hover:underline">Terms of Service</Link>
          <Link to="/contact" className="hover:underline">Contact Us</Link>
          <a href="https://www.linkedin.com/company/the-mental-health-program/about/?viewAsMember=true" target="_blank" rel="noopener noreferrer" className="hover:underline">
            <FaLinkedin className="text-gray-700 w-6 h-6" />
          </a>
        </div>

        {/* Cambridge SVG and Text on the Right */}
        <div className="flex items-center space-x-2">
          <img
            src="https://raw.githubusercontent.com/eddiebullock/landing-page-assets/e859468377a62d3d683e8b5c6aa0cc05f526e2fb/university-of-cambridge-2.svg"
            alt="Cambridge Logo"
            className="w-8 h-8"
          />
          <p className="text-sm">Developed by Doctoral Researchers</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
